.App {
  text-align: left;
}
::placeholder {
    font-style: italic;
    font-weight: normal;
}
.k-drawer-items {
    padding-top: 32px;
}
.k-drawer-start {
    max-width: 180px;
}
.k-table-md, .k-table-sm {
    font-size: 12px;
}
.k-column-title {
    color: steelblue;
}
.k-form .k-form-fieldset {
    margin-top: 8px;
}
.k-form .k-form-legend, .k-form-legend {
    margin-bottom: 0px;
    font-weight: bold;
    text-align: left;
}
.k-animation-container {
    display:flex;
}
.k-child-animation-container {
    flex-grow: 1;
}
.k-filtercell {
    border: dotted 2px #bf70cc
}
.inlineExamGrid .k-table-thead {
    background-color: palegreen;
}
.k-editor-content > .ProseMirror {
    -webkit-user-modify: read-only;
    overflow: hidden;
}
.sds-buttoninbar, .sds-buttoninbar .k-button {
    min-width:150px;
    border-left-color: white;
    border-left-Width: 5px;
}
.sds-questionnaire .k-form-field {
    margin-top: 32px;
}
.sds-questionnaire .k-input {
    background-color: rgba(173, 216, 230, 0.4);
}
.sds-questionnaire .k-dropdownlist {
    background-color: rgba(173, 216, 230, 0.4);
}
.sds-questionnaire .k-form-legend {
    color: steelblue;
}
.sds-questionnaire .k-form-field {
    margin-left: 16px;
}
.sds-questionnaire .sds-normal {
    margin-left: 16px;
}
.sds-onehealth .k-form-field {
    margin-top: 2px;
    margin-bottom: 2px;
}
.sds-clientcomm .k-form-field {
    margin-top: 0px;
}
.sds-hstack .k-hstack {
    border-width: 1px 0px 1px 0px;
    border-style:dotted;
}
.sds-clientcomm .k-form-field {
    margin-top: 0px;
}
.sds-clientcomm .k-hstack {
    border-width: 1px 0px 1px 0px;
    border-style:dotted;
}
.k-form-hint {
    font-weight: normal;
}
.k-window-content {
    padding-top:0px;
}
.k-editor-content {
    overflow-x:hidden;
}
/*
.k-tabstrip > .k-content.k-active {
    display: flex;
}
.k-animation-container {
    flex:auto;
}
*/
.sds-window .k-form-field {
    background-color: lightblue;
}
.sds-no-header .k-grid-header {
    display: none;
}
.sds-window .k-grid-table {
    background-color: lightblue;
    font-size: revert;
}
.sds-window .k-table-tbody {
    background-color: white;
    opacity: 0.7;
}
.sds-no-yscroll .k-grid-content {
    overflow-y: hidden;
}
.sds-window input {
    font-weight: bold;
}
.sds-window .k-content {
    padding-top: 12px;
    padding-bottom: 0px;
}
.sds-gridHeader {
    font-weight: bold;
    margin: 8px
}
.sds-selectable .k-grid-container {
    cursor:pointer;
}
.sds-bold input {
    font-weight: bold;
}
.sds-bold .k-input-value-text {
    font-weight: bold;
}
.sds-normal .k-input-value-text {
    font-weight: normal;
}
.sds-drawerSub {
    padding-left:16px;
}
.sds-uppercase {
    text-transform:uppercase;
}
.sds-stacklayout {
    display: flex;
    gap: 48px;
}
.sds-stacklayout .k-form-field {
    flex: 1 0;
}
input.sds-login {
    border: 3px solid #B4CFEC;
}

.examGrid .k-grid-header col:nth-of-type(1) {
    width: 7%
}
.examGrid .k-grid-table col:nth-of-type(1) {
    width: 7%
}
.examGrid .k-grid-header col:nth-of-type(2) {
    width: 20%
}
.examGrid .k-grid-table col:nth-of-type(2) {
    width: 20%
}
.examGrid .k-grid-header col:nth-of-type(3) {
    width: 17%
}
.examGrid .k-grid-table col:nth-of-type(3) {
    width: 17%
}
.examGrid .k-grid-header col:nth-of-type(4) {
    width: 6%
}
.examGrid .k-grid-table col:nth-of-type(4) {
    width: 6%
}
.examGrid .k-grid-header col:nth-of-type(5) {
    width: 9%
}
.examGrid .k-grid-table col:nth-of-type(5) {
    width: 9%
}
.examGrid .k-grid-header col:nth-of-type(6) {
    width: 8%
}
.examGrid .k-grid-table col:nth-of-type(6) {
    width: 8%
}
.examGrid .k-grid-header col:nth-of-type(7) {
    width: 8%
}
.examGrid .k-grid-table col:nth-of-type(7) {
    width: 8%
}
.examGrid .k-grid-header col:nth-of-type(8) {
    width: 8%
}
.examGrid .k-grid-table col:nth-of-type(8) {
    width: 8%
}
.examGrid .k-grid-header col:nth-of-type(9) {
    width: 8%
}
.examGrid .k-grid-table col:nth-of-type(9) {
    width: 8%
}
.examGrid .k-grid-header col:nth-of-type(10) {
    width: 9%
}
.examGrid .k-grid-table col:nth-of-type(10) {
    width: 9%
}

.petGrid .k-grid-header col:nth-of-type(1) {
    width: 11%
}

.petGrid .k-grid-table col:nth-of-type(1) {
    width: 11%
}

.petGrid .k-grid-header col:nth-of-type(2) {
    width: 11%
}

.petGrid .k-grid-table col:nth-of-type(2) {
    width: 11%
}

.petGrid .k-grid-header col:nth-of-type(3) {
    width: 10%
}
.petGrid .k-grid-table col:nth-of-type(3) {
    width: 10%
}

.petGrid .k-grid-header col:nth-of-type(4) {
    width: 6%
}

.petGrid .k-grid-table col:nth-of-type(4) {
    width: 6%
}

.petGrid .k-grid-header col:nth-of-type(5) {
    width: 6%
}

.petGrid .k-grid-table col:nth-of-type(5) {
    width: 6%
}

.petGrid .k-grid-header col:nth-of-type(6) {
    width: 8%
}

.petGrid .k-grid-table col:nth-of-type(6) {
    width: 8%
}

.petGrid .k-grid-header col:nth-of-type(7) {
    width: 8%
}

.petGrid .k-grid-table col:nth-of-type(7) {
    width: 8%
}

.petGrid .k-grid-header col:nth-of-type(8) {
    width: 6%
}

.petGrid .k-grid-table col:nth-of-type(8) {
    width: 6%
}

.petGrid .k-grid-header col:nth-of-type(9) {
    width: 7%
}

.petGrid .k-grid-table col:nth-of-type(9) {
    width: 7%
}

.petGrid .k-grid-header col:nth-of-type(10) {
    width: 12%
}

.petGrid .k-grid-table col:nth-of-type(10) {
    width: 12%
}

.petGrid .k-grid-header col:nth-of-type(11) {
    width: 9%
}

.petGrid .k-grid-table col:nth-of-type(11) {
    width: 9%
}
.petGrid .k-grid-header col:nth-of-type(12) {
    width: 6%
}

.petGrid .k-grid-table col:nth-of-type(12) {
    width: 6%
}

.inlineExamGrid .k-grid-header col:nth-of-type(1) {
    width: 8%
}

.inlineExamGrid .k-grid-table col:nth-of-type(1) {
    width: 8%
}

.inlineExamGrid .k-grid-header col:nth-of-type(2) {
    width: 34%
}

.inlineExamGrid .k-grid-table col:nth-of-type(2) {
    width: 34%
}

.inlineExamGrid .k-grid-header col:nth-of-type(3) {
    width: 8%
}

.inlineExamGrid .k-grid-table col:nth-of-type(3) {
    width: 8%
}

.inlineExamGrid .k-grid-header col:nth-of-type(4) {
    width: 10%
}

.inlineExamGrid .k-grid-table col:nth-of-type(4) {
    width: 10%
}

.inlineExamGrid .k-grid-header col:nth-of-type(5) {
    width: 10%
}

.inlineExamGrid .k-grid-table col:nth-of-type(5) {
    width: 10%
}

.inlineExamGrid .k-grid-header col:nth-of-type(6) {
    width: 10%
}

.inlineExamGrid .k-grid-table col:nth-of-type(6) {
    width: 10%
}

.inlineExamGrid .k-grid-header col:nth-of-type(7) {
    width: 10%
}

.inlineExamGrid .k-grid-table col:nth-of-type(7) {
    width: 10%
}

.inlineExamGrid .k-grid-header col:nth-of-type(8) {
    width: 10%
}

.inlineExamGrid .k-grid-table col:nth-of-type(8) {
    width: 10%
}
.inlinePersonGrid .k-grid-header col:nth-of-type(1) {
    width: 30%
}

.inlinePersonGrid .k-grid-table col:nth-of-type(1) {
    width: 30%
}

.inlinePersonGrid .k-grid-header col:nth-of-type(2) {
    width: 30%
}

.inlinePersonGrid .k-grid-table col:nth-of-type(2) {
    width: 30%
}

.inlinePersonGrid .k-grid-header col:nth-of-type(3) {
    width: 30%
}

.inlinePersonGrid .k-grid-table col:nth-of-type(3) {
    width: 30%
}

.inlinePersonGrid .k-grid-header col:nth-of-type(4) {
    width: 10%
}

.inlinePersonGrid .k-grid-table col:nth-of-type(4) {
    width: 10%
}

.inlinePetGrid .k-grid-header col:nth-of-type(1) {
    width: 23%
}
.inlinePetGrid .k-grid-table col:nth-of-type(1) {
    width: 23%
}

.inlinePetGrid .k-grid-header col:nth-of-type(2) {
    width: 23%
}

.inlinePetGrid .k-grid-table col:nth-of-type(2) {
    width: 23%
}

.inlinePetGrid .k-grid-header col:nth-of-type(3) {
    width: 23%
}

.inlinePetGrid .k-grid-table col:nth-of-type(3) {
    width: 23%
}

.inlinePetGrid .k-grid-header col:nth-of-type(4) {
    width: 23%
}

.inlinePetGrid .k-grid-table col:nth-of-type(4) {
    width: 23%
}
.inlinePetGrid .k-grid-header col:nth-of-type(5) {
    width: 8%
}

.inlinePetGrid .k-grid-table col:nth-of-type(5) {
    width: 8%
}

.eventGrid .k-grid-header col:nth-of-type(1) {
    width: 25%
}

.eventGrid .k-grid-table col:nth-of-type(1) {
    width: 25%
}

.eventGrid .k-grid-header col:nth-of-type(2) {
    width: 25%
}

.eventGrid .k-grid-table col:nth-of-type(2) {
    width: 25%
}

.eventGrid .k-grid-header col:nth-of-type(3) {
    width: 35%
}

.eventGrid .k-grid-table col:nth-of-type(3) {
    width: 35%
}

.eventGrid .k-grid-header col:nth-of-type(4) {
    width: 15%
}

.eventGrid .k-grid-table col:nth-of-type(4) {
    width: 15%
}

.schedGrid .k-grid-header col:nth-of-type(1) {
    width: 7%
}

.schedGrid .k-grid-table col:nth-of-type(1) {
    width: 7%
}

.schedGrid .k-grid-header col:nth-of-type(2) {
    width: 8%
}

.schedGrid .k-grid-table col:nth-of-type(2) {
    width: 8%
}

.schedGrid .k-grid-header col:nth-of-type(3) {
    width: 7%
}

.schedGrid .k-grid-table col:nth-of-type(3) {
    width: 7%
}

.schedGrid .k-grid-header col:nth-of-type(4) {
    width: 7%
}

.schedGrid .k-grid-table col:nth-of-type(4) {
    width: 7%
}

.schedGrid .k-grid-header col:nth-of-type(5) {
    width: 19%
}

.schedGrid .k-grid-table col:nth-of-type(5) {
    width: 19%
}

.schedGrid .k-grid-header col:nth-of-type(6) {
    width: 8%
}

.schedGrid .k-grid-table col:nth-of-type(6) {
    width: 8%
}

.schedGrid .k-grid-header col:nth-of-type(7) {
    width: 20%
}

.schedGrid .k-grid-table col:nth-of-type(7) {
    width: 20%
}

.schedGrid .k-grid-header col:nth-of-type(8) {
    width: 14%
}

.schedGrid .k-grid-table col:nth-of-type(8) {
    width: 14%
}

.schedGrid .k-grid-header col:nth-of-type(9) {
    width: 9%
}

.schedGrid .k-grid-table col:nth-of-type(9) {
    width: 9%
}

.personGrid .k-grid-header col:nth-of-type(1) {
    width: 15%
}

.personGrid .k-grid-table col:nth-of-type(1) {
    width: 15%
}

.personGrid .k-grid-header col:nth-of-type(2) {
    width: 15%
}

.personGrid .k-grid-table col:nth-of-type(2) {
    width: 15%
}

.personGrid .k-grid-header col:nth-of-type(3) {
    width: 25%
}

.personGrid .k-grid-table col:nth-of-type(3) {
    width: 25%
}

.personGrid .k-grid-header col:nth-of-type(4) {
    width: 25%
}

.personGrid .k-grid-table col:nth-of-type(4) {
    width: 25%
}

.personGrid .k-grid-header col:nth-of-type(5) {
    width: 10%
}

.personGrid .k-grid-table col:nth-of-type(5) {
    width: 10%
}

.personGrid .k-grid-header col:nth-of-type(6) {
    width: 10%
}

.personGrid .k-grid-table col:nth-of-type(6) {
    width: 10%
}

.clientGrid .k-grid-header col:nth-of-type(1) {
    width: 15%
}

.clientGrid .k-grid-table col:nth-of-type(1) {
    width: 15%
}

.clientGrid .k-grid-header col:nth-of-type(2) {
    width: 20%
}

.clientGrid .k-grid-table col:nth-of-type(2) {
    width: 20%
}

.clientGrid .k-grid-header col:nth-of-type(3) {
    width: 30%
}

.clientGrid .k-grid-table col:nth-of-type(3) {
    width: 30%
}

.clientGrid .k-grid-header col:nth-of-type(4) {
    width: 25%
}

.clientGrid .k-grid-table col:nth-of-type(4) {
    width: 25%
}

.clientGrid .k-grid-header col:nth-of-type(5) {
    width: 10%
}

.clientGrid .k-grid-table col:nth-of-type(5) {
    width: 10%
}

.agencyGrid .k-grid-header col:nth-of-type(1) {
    width: 27%
}

.agencyGrid .k-grid-table col:nth-of-type(1) {
    width: 27%
}

.agencyGrid .k-grid-header col:nth-of-type(2) {
    width: 12%
}

.agencyGrid .k-grid-table col:nth-of-type(2) {
    width: 12%
}

.agencyGrid .k-grid-header col:nth-of-type(3) {
    width: 10%
}

.agencyGrid .k-grid-table col:nth-of-type(3) {
    width: 10%
}

.agencyGrid .k-grid-header col:nth-of-type(4) {
    width: 11%
}

.agencyGrid .k-grid-table col:nth-of-type(4) {
    width: 11%
}

.agencyGrid .k-grid-header col:nth-of-type(5) {
    width: 11%
}

.agencyGrid .k-grid-table col:nth-of-type(5) {
    width: 11%
}

.agencyGrid .k-grid-header col:nth-of-type(6) {
    width: 11%
}

.agencyGrid .k-grid-table col:nth-of-type(6) {
    width: 11%
}

.agencyGrid .k-grid-header col:nth-of-type(7) {
    width: 11%
}

.agencyGrid .k-grid-table col:nth-of-type(7) {
    width: 11%
}

.agencyGrid .k-grid-header col:nth-of-type(8) {
    width: 6%
}

.agencyGrid .k-grid-table col:nth-of-type(8) {
    width: 6%
}

.serviceGrid .k-grid-header col:nth-of-type(1) {
    width: 35%
}

.serviceGrid .k-grid-table col:nth-of-type(1) {
    width: 35%
}

.serviceGrid .k-grid-header col:nth-of-type(2) {
    width: 65%
}

.serviceGrid .k-grid-table col:nth-of-type(2) {
    width: 65%
}

.findingGrid .k-grid-header col:nth-of-type(1) {
    width: 15%
}

.findingGrid .k-grid-table col:nth-of-type(1) {
    width: 15%
}

.findingGrid .k-grid-header col:nth-of-type(2) {
    width: 85%
}

.findingGrid .k-grid-table col:nth-of-type(2) {
    width: 85%
}

.vaccineGrid .k-grid-header col:nth-of-type(1) {
    width: 15%
}

.vaccineGrid .k-grid-table col:nth-of-type(1) {
    width: 15%
}

.vaccineGrid .k-grid-header col:nth-of-type(2) {
    width: 85%
}

.vaccineGrid .k-grid-table col:nth-of-type(2) {
    width: 85%
}

.eventsGrid .k-grid-header col:nth-of-type(1) {
    width: 15%
}
.eventsGrid .k-grid-table col:nth-of-type(1) {
    width: 15%
}
.eventsGrid .k-grid-header col:nth-of-type(2) {
    width: 35%
}
.eventsGrid .k-grid-table col:nth-of-type(2) {
    width: 35%
}
.eventsGrid .k-grid-header col:nth-of-type(3) {
    width: 30%
}
.eventsGrid .k-grid-table col:nth-of-type(3) {
    width: 30%
}
.eventsGrid .k-grid-header col:nth-of-type(4) {
    width: 20%
}
.eventsGrid .k-grid-table col:nth-of-type(4) {
    width: 20%
}

.serviceRequestedGrid .k-grid-header col:nth-of-type(1) {
    width: 15%
}

.serviceRequestedGrid .k-grid-table col:nth-of-type(1) {
    width: 15%
}

.serviceRequestedGrid .k-grid-header col:nth-of-type(2) {
    width: 35%
}

.serviceRequestedGrid .k-grid-table col:nth-of-type(2) {
    width: 35%
}

.serviceRequestedGrid .k-grid-header col:nth-of-type(3) {
    width: 20%
}

.serviceRequestedGrid .k-grid-table col:nth-of-type(3) {
    width: 20%
}

.serviceRequestedGrid .k-grid-header col:nth-of-type(4) {
    width: 20%
}

.serviceRequestedGrid .k-grid-table col:nth-of-type(4) {
    width: 20%
}

.serviceRequestedGrid .k-grid-header col:nth-of-type(5) {
    width: 10%
}

.serviceRequestedGrid .k-grid-table col:nth-of-type(5) {
    width: 10%
}
